@use "../abstracts" as *;
@use "../framework/button" as *;

.app-c-btn {
  @include button;
  @extend %inline-flex-all-center;
  padding: 15px;
  font-family: $body-font;
  font-size: 15px;
  font-weight: $font-weight-semi-bold;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: $btn-border-radius;
  background-color: transparent;
  background-clip: padding-box;
  /* prevents background overflowing transparent border color */
  transition: border-color $animation-duration-3;
  position: relative;
  @extend %touch-tap-none;
  height: 48px;
  min-width: 110px !important;
  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  // button -- primary
  &--primary {
    background-color: #6e6e5c !important;
    // border: 1px solid #707070 !important;
    border-radius: $btn-border-radius !important;
    font-weight: $font-weight-semi-bold !important;
    color: $white !important;
    font-family: $body-font !important;
    font-size: 14px !important;
    text-decoration: none !important;
    text-transform: initial !important;
    box-shadow: none !important;
    padding: 11px 16px;
    height: 40px;

    .svg-icon {
      margin-right: 12px;
    }

    &:hover {
      background-color: #545446 !important;
      // border-color: #707070 !important;
    }
  }

  &--remove {
    background-color: rgb(247 246 245) !important;
    border: 1px solid $grey6 !important;
    border-radius: $btn-border-radius !important;
    font-weight: $font-weight-semi-bold !important;
    color: $grid-content !important;
    font-family: $body-font !important;
    font-size: 14px !important;
    text-decoration: none !important;
    text-transform: initial !important;
    box-shadow: none !important;
    padding: 11px 16px;
    height: 48px;

    .svg-icon {
      color: $grey4;
      margin-right: 8px;
    }

    &:hover {
      border-color: $black !important;
    }
  }

  &--secondary {
    background-color: #faf6f2 !important;
    // border: 1px solid #707070 !important;
    border-radius: $btn-border-radius !important;
    font-weight: $font-weight-semi-bold !important;
    color: $grid-content !important;
    font-family: $body-font !important;
    font-size: 14px !important;
    text-decoration: none !important;
    text-transform: initial !important;
    box-shadow: none !important;
    padding: 11px 16px;
    height: 40px;

    .svg-icon {
      color: $grey4;
      margin-right: 8px;
    }

    &:hover {
      background-color: #f4ede5 !important;
      // border-color: #707070 !important;
    }
  }

  &--primary,
  &--secondary,
  &--remove {
    border-radius: 5px;
    padding: 9px 15px;
  }

  &.icon-button {
    @extend %flex-vert-center;
  }

  &--black {
    background-color: $black !important;
    border-color: $black !important;
    border-radius: $btn-border-radius !important;
    font-weight: $font-weight-semi-bold !important;
    color: $white !important;
    font-family: $body-font !important;
    font-size: 15px !important;
    text-decoration: none !important;
    text-transform: initial !important;
    box-shadow: none !important;

    .icon-google {
      margin-right: 12px;
      transition: all ease 0.3s;
    }

    .icon-arrow-right {
      color: $grey11 !important;
      margin-left: 12px;
      transition: all ease 0.3s;
    }

    &:hover {
      background: $black !important;
      border-color: $black !important;

      .icon-google {
        margin-right: 20px;
      }

      .icon-arrow-right {
        color: $white !important;
        margin-left: 20px;
      }
    }
  }

  &--filter {
    background-color: $white !important;
    border: 1px solid #e2e2e2 !important;
    border-radius: $btn-border-radius !important;
    font-weight: $font-weight-semi-bold !important;
    color: $grid-content !important;
    font-family: $body-font !important;
    font-size: 14px !important;
    text-decoration: none !important;
    text-transform: initial !important;
    box-shadow: none !important;
    padding: 11px 14px;
    height: 37px;
    margin-left: 4px !important;
    .icon-filter,
    .icon-export {
      color: $grey4;
      margin-right: 12px;
    }

    .icon-chevron-down {
      color: $grey4;
      margin-left: 12px;
    }

    &:hover {
      border-color: #e8daca !important;
    }
  }

  &--search {
    background: #968a7e !important;
    box-shadow: none !important;
    border-radius: 0px 8px 8px 0px !important;
    border: none !important;
    padding: 6px 16px !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: normal !important;
    color: #ffffff !important;
    // position: absolute !important;
    // right: 6px;
    // top: 7px;
    height: 39px;
    text-transform: capitalize !important;

    svg {
      margin-right: 6px;
    }
  }

  &--profile-image-upload {
    background-color: $body-bg !important;
    width: 38px;
    height: 38px;
  }
  &--link {
    // padding: 2px 4px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    // line-height: 17px !important;
    color: #6e6e5c !important;
    width: 160px !important;
    background: transparent !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
    border: 1px solid #6e6e5c !important;
    height: 40px !important;
    border-radius: 8px !important;
    .svg-icon {
      margin-left: 8px;
      width: 11px;
      height: 11px;
    }
    &:hover {
      background: #f5f2e9 !important;
    }
  }
  &--embossed {
    background: $white !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06) !important;
    border-radius: 8px !important;
    border: none !important;
    padding: 13px 12px 11px !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: normal !important;
    color: $black !important;
    height: 40px;
    text-transform: capitalize !important;

    .svg-icon {
      margin-right: 12px;
    }
  }
  + .app-c-btn {
    margin-left: 12px;
  }
  &.Mui-disabled {
    opacity: 0.6 !important;
  }
}
