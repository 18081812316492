@use '../abstracts' as *;

.MuiTooltip-popper {
    .MuiTooltip-tooltip {
        margin-bottom: 6px !important;
        background-color: $black;
        border-radius: 6px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.24);
        font-weight: 400;
        font-size: 12px;
        line-height: normal;
        color: $white;
    }
    .MuiTooltip-arrow {
        color: $black;
        width: 10px;
        height: 6px;
        margin-bottom: -6px !important;
    }
}