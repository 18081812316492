@use 'abstracts' as *;

:root {
    --asc-display: none;
    --desc-display: none;
    --no-sort-display: block;
}

html,
body {
    height: 100%;
}

html {
    font-size: 62.5%;
}

body {
    font-family: $body-font;
    font-size: $text-md;
    /* 14px */
    font-weight: $font-weight-regular;
}

#root {
    @extend %flex-col;
    height: 100%;
    min-height: 0;
}

button {
    font-family: $body-font;
}

.app-l-page-wrap {
    @extend %flex-row;
    width: 100%;
    height: 100%;
    min-height: 0;
    overflow: hidden;
}

.app-l-content-wrap {
    @extend %flex-col;
    width: 100%;
    height: 100%;
    min-height: 0;
    min-width: 0;
    transition: all ease-in-out 0.3s;

    @media (max-width: $screen-ipad-landscape) {
        padding-left: 72px;
    }
}

.app-l-content-inner-wrap {
    padding: 22px 30px 16px 32px;
    height: 100%;
    min-height: 0;
}

.icon-button {
    @extend %flex;

    .svg-icon+.button-text {
        display: inline-block;
        margin-left: 15px;
    }
}

.icon-button .button-text {
    margin-left: 8px;
    margin-right: 3px;
}

.export-btn .button-text {
    margin-right: 10px;
}

.sr-only,
.visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

::-webkit-scrollbar {
    width: 4px;
    height: 7px;
}

::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    // -webkit-border-radius: 10px;
    // border-radius: 10px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: rgba(191, 195, 206, 0.5);
    // -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(191, 195, 206, 0.5);
    -webkit-border-radius: 5px;
    border-radius: 5px;
}