@use "../abstracts" as *;
@use "../framework/check-radio" as *;

.app-c-checkbox {
  margin-left: 0px !important;
  .MuiCheckbox-root {
    padding: 0px;
    .MuiSvgIcon-root {
      width: 22px;
      height: 22px;
      color: #e3d1bd !important;
    }
    input[type="checkbox"] {
      width: 22px;
      height: 22px;
      border: 1px solid #e3d1bd;
      border-radius: 6px;
      &:checked {
        + .MuiSvgIcon-root {
          color: #e3d1bd !important;
        }
      }
    }
  }
  .MuiTypography-root {
    font-size: 13px;
    line-height: normal;
    color: $grey3;
    margin-left: 8px;
  }
  + .app-c-checkbox {
    margin-top: 6px;
  }
}
