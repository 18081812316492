@use "../abstracts/palette" as *;
@use "../abstracts/typography" as *;
@use "../abstracts/variables" as *;

.app-c-status {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  gap: 6px;
  //   &::before {
  //     content: "";
  //     width: 9px;
  //     height: 9px;
  //     margin-right: 6px;
  //     display: inline-block;
  //     border-radius: 50%;
  //   }
  &.active {
    color: #86b373;

    // &::before {
    //   background: $status-active;
    // }
  }

  &.inactive {
    color: #888888;

    // &::before {
    //   background: $status-inactive;
    // }
  }

  &.deceased {
    color: #888888;

    // &::before {
    //   background: $status-decesed;
    // }
  }

  &.defected {
    color: #b64f49;

    // &::before {
    //   background: $status-defected;
    // }
  }

  &.complete {
    color: $status-complete;

    &::before {
      background: $status-complete;
    }
  }

  &.pending {
    color: $status-pending;

    &::before {
      background: $status-pending;
    }
  }

  &.verified-success {
    color: $status-verified-success;

    &::before {
      background: $status-verified-success1;
    }
  }

  &.verified-failed {
    color: $status-verified-failed;

    &::before {
      background: $status-verified-failed;
    }
  }

  &.qa-pending {
    color: $status-pending;

    &::before {
      background: $status-pending;
    }
  }

  &.qa-success {
    color: $status-qa-success;

    &::before {
      background: $status-qa-success;
    }
  }

  &.qa-failed {
    color: $status-qa-failed;

    &::before {
      background: $status-qa-failed;
    }
  }

  &.assigned {
    color: #4189dd;

    &::before {
      display: none;
    }

    .svg-icon {
      margin-right: 6px;
    }
  }

  &.issued {
    color: $status-issued;

    &::before {
      background: $status-issued;
    }
  }

  &.lost {
    color: $status-lost;

    &::before {
      background: $status-lost;
    }
  }

  &.stored {
    color: $status-stored;

    &::before {
      background: $status-stored;
    }
  }

  &.destroyed {
    color: $status-destroyed;

    &::before {
      background: $status-destroyed;
    }
  }
}

.app-c-status--with-bg {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  padding: 3px 6px;
  border-radius: $border-radius2;

  &.generated {
    color: $white;
    background: $brand-primary;
  }
}

.app-c-status--with-border {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  padding: 3px 6px;
  border-radius: $border-radius2;

  &.generated {
    color: $brand-primary;
    background: $white;
    box-shadow: inset 0px 0px 0px 1px $brand-primary;
  }
}

.app-c-status--vertical {
  display: flex;
  gap: 6px;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  //   content: "";
  //   position: absolute;
  //   left: 0px;
  //   right: auto;
  //   top: 1px;
  //   bottom: 1px;
  //   width: 5px;
  // height: 100%;

  &.issued {
    color: #86b373;
  }

  //   &.marked-to-destroy {
  //     background: #9c9c9c;
  //   }

  &.sold,
  &.lost {
    color: #b64f49;
  }

  &.reclaimed-by-clan,
  &.stored {
    color: #517dbb;
  }
  &.in-transit,
  &.draft,
  &.marked-to-destroy {
    color: #c2b653;
  }

  &.unknown,
  &.destroyed,
  &.expended {
    color: #888888;
  }

  //   &.expended {
  //     background: #7dc8e0;
  //   }
}

.app-c-status--log {
  padding: 6px 8px;
  text-align: center;
  border-radius: 6px;
  width: fit-content;

  &.create {
    background-color: #26c04e;
    color: white;
  }

  &.update {
    background-color: #4baef8;
    color: white;
  }
}

.app-c-status--logColumn {
  &.new {
    background-color: #e3eec7;
  }

  &.edit {
    background-color: #fdf9ce;
  }

  &.delete {
    background-color: #fee2e1;
  }
}
