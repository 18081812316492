@use "../abstracts" as *;

.app-c-grid-layout {
  box-sizing: border-box;
  @extend %flex-row;
  @extend %flex-wrap;
  margin-top: -12px;
  width: calc(100% + 12px);
  margin-left: -12px;

  .app-c-grid-layout__item {
    padding-top: 12px;
    padding-left: 12px;
    box-sizing: border-box;
    flex-grow: 0;
  }

  &--two-column {
    .app-c-grid-layout__item {
      flex-basis: 50%;
    }
  }

  &--three-column {
    .app-c-grid-layout__item {
      flex-basis: 33.33%;
    }
  }

  &--four-column {
    .app-c-grid-layout__item {
      flex-basis: 25%;

      .MuiFormControl-root-MuiTextField-root {
        width: 100%;
      }
    }
  }

  &--five-column {
    .app-c-grid-layout__item {
      flex-basis: 20%;

      .MuiFormControl-root-MuiTextField-root {
        width: 100%;
      }
    }
  }

  &--six-column {
    .app-c-grid-layout__item {
      flex-basis: 16.66%;

      .MuiFormControl-root-MuiTextField-root {
        width: 100%;
      }
    }
  }

  &--seven-column {
    .app-c-grid-layout__item {
      flex-basis: 14.28%;

      .MuiFormControl-root-MuiTextField-root {
        width: 100%;
      }
    }
  }
}

.app-c-timeline {
  .MuiStepIcon-text {
    display: none;
  }

  .MuiStepContent-root,
  .MuiStepConnector-root {
    margin-left: 6px;
  }

  .MuiStepLabel-iconContainer {
    padding-right: 20px;
  }

  .MuiStepContent-root {
    padding-left: 27px;
    border-color: $grid-border;
  }

  .MuiStepLabel-label {
    font-family: $body-font;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $grey5;
  }

  .MuiStepLabel-label.Mui-active {
    color: $grey5;
  }

  .MuiTypography-root {
    font-family: $body-font;
    font-weight: 500;
    font-size: 13px;
    line-height: normal;
    color: $grey3;
    word-break: break-word;
  }

  .MuiStepConnector-line {
    border-color: $grid-border;
    min-height: 16px;
  }

  .MuiSvgIcon-root {
    width: 12px;
    height: 12px;
  }

  .MuiSvgIcon-root.Mui-active {
    color: $brand-primary;
  }

  &--horizontal {
    .MuiStepContent-root,
    .MuiStepConnector-root {
      margin-left: 6px;
    }

    .MuiStepLabel-iconContainer {
      padding-right: 10px;
    }

    .MuiStepContent-root {
      padding-left: 27px;
      border-color: $grid-border;
    }

    .MuiStepLabel-label {
      font-family: $body-font;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      color: $grey5;
    }

    .MuiStepLabel-label.Mui-active {
      color: $grey3;
    }

    .MuiTypography-root {
      font-family: $body-font;
      font-weight: 500;
      font-size: 13px;
      line-height: normal;
      color: $grey3;
      word-break: break-word;
    }

    .MuiStepConnector-line {
      border-color: #8d9091;
      min-height: 1px;
      border-top-style: dashed;
    }

    .MuiSvgIcon-root {
      width: 29px;
      height: 29px;
      border: 1px solid #8d9091;
      background: $white;
      border-radius: 8px;
      color: transparent;

      .MuiStepIcon-text {
        fill: #8d9091;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }
    }

    .MuiSvgIcon-root.Mui-active {
      border: none;
      color: $brand-primary;

      circle {
        cx: 12;
        cy: 12;
        r: 15;
      }

      .MuiStepIcon-text {
        fill: $white;
      }
    }
  }
}

.app-l-page-footer {
  text-align: right;
  padding: 8px 36px 11px;
  border-top: 1px solid $header-border-color;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .details-for-transfer-text {
    font-weight: 600;
    font-size: 13px;
    color: #272c37;

    .app-c-btn--link {
      min-width: auto !important;
      margin-left: 14px;
    }
  }

  .review-transfer-text {
    font-weight: 600;
    font-size: 13px;
    line-height: normal;
    color: $grey3;
    margin-right: 16px;
  }

  .transfer-count {
    margin-left: 10px;
    padding: 2px 8px;
    background: $grey6;
    border-radius: 8px;
    color: $grey1;
    width: 43px;
    line-height: normal;
  }

  .app-l-page-footer__left-text {
    margin-right: auto;
    font-weight: 400;
    font-size: 13px;
    line-height: normal;
    color: #272c37;
    display: flex;
    align-items: center;

    svg {
      color: #8d9091;
      margin-right: 8px;
    }
  }

  .app-l-page-footer__confirm-text {
    font-weight: 500;
    font-size: 13px;
    color: #272c37;
    line-height: normal;
    margin-right: 16px;
  }

  .app-l-page-footer__left {
    margin-right: auto;
    text-align: start;
  }

  .app-l-page-footer__right {
    margin-left: auto;
  }

  &.app-l-footer--expanding {
    align-items: flex-start;
    padding: 12px 32px 11px;

    .form-group--textarea {
      margin-top: 24px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 12px;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #bfc3ce;
        top: -12px;
        right: 25px;
      }

      &::after {
        content: "";
        position: absolute;
        width: 22px;
        height: 12px;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #ffffff;
        top: -11px;
        right: 25px;
      }
    }

    .app-l-page-footer__right {
      .form-group {
        @extend %inline-flex-vert-center;
        flex-grow: 1;

        .MuiAutocomplete-root {
          min-width: 250px;

          + .MuiAutocomplete-root {
            margin-left: 12px;
          }
        }

        .app-c-btn {
          margin-left: 12px;
          min-width: 135px !important;
          flex-shrink: 0;
        }
      }
    }
  }
}

.app-l-boxed-container--with-bg {
  border-radius: 8px;
  // padding: 19px 24px;
  margin-top: 20px;
  border: 1px solid #e8daca;

  @media (max-width: $screen-md) {
    padding: 16px 16px;
  }

  .app-c-grid-layout {
    position: relative;
    margin-left: 0px;

    + .app-c-grid-layout {
      margin-top: 16px;
    }

    // &::before {
    //   content: "";
    //   position: absolute;
    //   left: 0px;
    //   right: auto;
    //   top: 22px;
    //   bottom: 0px;
    //   width: 3px;
    // }

    .app-c-grid-layout__item {
      // padding-left: 24px;

      @media (max-width: $screen-ipad-landscape) {
        flex-basis: 50%;
      }

      @media (max-width: $screen-md) {
        flex-basis: 100%;
      }
    }
  }

  .app-l-boxed-container--with-bg__heading {
    @extend %flex-vert-center;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $grey3;
    margin-top: 0px;
    // margin-bottom: 10px;
    border-bottom: 1px solid #e8daca;
    padding: 12px 24px;

    .item-count {
      padding: 4px 9px 3px 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $grey3;
      background: $grid-header-bg;
      border-radius: 8px;
      margin-left: 10px;
    }
  }

  &.pale-orange {
    background: $body-bg;

    .app-c-grid-layout {
      &::before {
        background: $unit-details-bg;
      }
    }

    .item-count {
      background: #e3d1bd;
    }
  }

  &.pale-green {
    background: #fcfbf8;

    .app-c-grid-layout {
      padding: 19px 12px;
      // &::before {
      //   background: $pale-green-shaded;
      // }
    }

    .item-count {
      background: #e3d1bd;
    }
  }

  &.white {
    background: $white;

    .app-c-grid-layout {
      &::before {
        background: $unit-details-bg;
      }
    }
  }

  + .app-l-boxed-container--with-bg {
    margin-top: 12px;
  }
}

.app-c-profile__image-upload-blk {
  width: 100%;
  position: relative;

  .app-c-profile__image-upload__img-wrap {
    width: 137px;
    height: 137px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background: rgba($color: $white, $alpha: 0.4);
      border-radius: 8px;
      visibility: hidden;
      opacity: 0;
      transition: all ease 0.3s;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  .app-c-btn--profile-image-upload {
    position: absolute;
    right: 5px;
    bottom: 8px;
    width: 38px;
    height: 38px;
    min-width: auto !important;
    z-index: 1;

    &:hover {
      + .app-c-profile__image-upload__img-wrap {
        &::before {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.app-l-upload-image-item-blk {
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $black1;
    margin-bottom: 13px;
    @extend %inline-flex-vert-center;

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: normal;
      color: $grey5;
      margin-left: 12px;
      @extend %inline-flex-vert-center;

      svg {
        margin-right: 9px;
      }
    }
  }

  h4 {
    margin-top: 11px;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #272c37;
    @extend %inline-flex-vert-center;

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: normal;
      color: $grey5;
      margin-left: 12px;
      @extend %inline-flex-vert-center;

      svg {
        margin-right: 9px;
      }
    }
  }
}

.app-l-upload-image-item__wrap {
  @extend %flex;
  @extend %flex-wrap;
  margin: -8px -8px;

  .app-l-upload-image-item {
    width: 158px;
    height: 158px;
    margin: 8px 8px;
    background: #f4ede5;
    border: 1.5px dashed $upload-image-border;
    border-radius: 8px;
    @extend %flex-all-center-col;
    position: relative;

    .MuiButtonBase-root {
      width: 32px;
      height: 32px;
      background: #fcfbf8;
      border-radius: 42px;
      color: $menu-text-active;
    }

    .app-l-upload-image-item__heading {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $menu-text;
      margin-top: 8px;
      margin-bottom: 3px;
    }

    .app-l-upload-image-item__text {
      margin-top: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      padding: 0px 30px;
      color: #5b5c58;
      text-align: center;
    }

    .app-l-uploaded-image {
      border-radius: 8px;
      position: absolute;
      width: 100%;
      height: 100%;
      background: #f4ede5;
      z-index: 1;
      @extend %flex-all-center-col;
      visibility: hidden;
      opacity: 0;
      transition: visibility ease 0.3s, opacity ease 0.3s;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }

      .download-btn,
      .delete-btn {
        width: 24px !important;
        height: 24px !important;
        background: $white !important;
        border-radius: 50% !important;
        position: absolute;
        right: 10px;
        top: 10px;
        color: $menu-text !important;
        padding: 0px !important;
      }

      .img-edit-btn {
        width: 24px !important;
        height: 24px !important;
        background: $white !important;
        border-radius: 50% !important;
        position: absolute;
        right: 42px;
        top: 10px;
        color: $menu-text !important;
        padding: 0px !important;
      }

      .delete-btn1 {
        width: 12px !important;
        height: 12px !important;
        background: $white !important;
        border-radius: 50% !important;
        position: absolute;
        right: 8px;
        top: 5px;
        color: $menu-text !important;
        padding: 0px !important;
      }

      .app-l-uploaded-image__type {
        position: absolute;
        bottom: 0px;
        top: auto;
        left: 0px;
        right: 0px;
        padding: 3px 0px;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: $menu-text-active;
        background: $white;
        width: 100%;
        border-radius: 0px 0px 8px 8px;
        text-align: center;
      }
    }

    &.image-uploaded {
      border-color: #e8daca;
      border-style: dashed;

      .app-l-uploaded-image {
        visibility: visible;
        opacity: 1;
      }
    }

    &.pdf-uploaded {
      border-color: $grey6;
      border-style: solid;

      .app-l-uploaded-image {
        visibility: visible;
        opacity: 1;
        background: $white;

        img {
          object-fit: none;
          width: auto;
          height: auto;
        }
      }

      .app-l-uploaded-file__name {
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
        color: $black;
        margin-top: 8px;
      }

      .app-l-uploaded-file__size {
        font-weight: 600;
        font-size: 9px;
        line-height: 12px;
        text-align: center;
        color: $menu-text;
      }
    }
  }
}

.app-l-accordion__search-btn {
  height: 53px;
  min-width: 110px !important;
}

.app-l-accordion__search-results {
  padding: 16px 24px 0px;
  border-top: 1px solid $header-border-color;
  margin: 22px -24px 0px;
  overflow: auto;
  max-height: 400px;

  .app-l-search-results__count {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $grey5;
  }

  .app-l-search-results__wrap {
    margin-top: 24px;
  }

  .app-l-search-results__item {
    @extend %flex-row;

    .app-l-search-results__item__left {
      min-width: 123px;
      max-width: 123px;
      min-height: 135px;
      max-height: 140px;
      border-radius: 8px;
      background: $white;
      margin-right: 32px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
      }
    }

    .app-l-search-results__item__right {
      width: 100%;

      .result-action-btn-blk {
        margin-top: 15px;
      }
    }

    + .app-l-search-results__item {
      margin-top: 15px;
    }
  }
}

.app-c-search-bar {
  max-width: 345px;
  align-items: center;

  form {
    position: relative;
    min-width: 345px;

    .form-control {
      background: #ffffff;
      border: 1px solid #e8daca;
      // border-radius: 8px;
      padding: 11px 100px 11px 20px;
      width: 100%;
      outline: none;
      font-size: 14px;
      line-height: 16px;
    }

    .vertical-line {
      position: absolute;
      left: 35px;
      top: 4px;
      height: 32px;
      width: 1px;
      background: #e2e2de;
    }

    .search-icon {
      position: absolute;
      left: 12px;
      top: 12px;
      color: #6b6a66;
    }

    .search-text {
      font-weight: 500;
      font-size: 14px;
      line-height: normal;
      color: $grey5;
      position: absolute;
      left: 41px;
      top: 11px;
    }
  }

  &--map {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 405;

    .form-control {
      background: $white !important;
      border-color: $grey6 !important;
    }

    .app-c-btn--search {
      min-width: auto !important;
    }
  }

  &--with-dropdown {
    position: absolute;
    top: 16px;
    right: 16px;
    left: auto;
    margin: auto;
    z-index: 405;
    max-width: 425px;

    .form-group {
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
    }

    .form-control {
      background: $white !important;
      border-color: $grey6 !important;
    }

    .select {
      width: 140px !important;
      padding: 10px 16px 10px 16px !important;
      border-radius: 8px 0px 0px 8px !important;
      border-right: none !important;

      option {
        padding: 5px 0px;
      }
    }

    .app-c-btn--search {
      min-width: auto !important;
    }

    .search-box-wrap {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 24px;
        left: 7px;
        right: auto;
        top: 0px;
        bottom: 0px;
        margin: auto;
        background: #e8daca;
      }

      .form-control {
        // border-radius: 0px 6px 6px 0px !important;
        border-left: none !important;
      }
    }

    .input-group {
      .MuiFilledInput-root {
        border-radius: 6px 0px 0px 6px !important;
      }

      .MuiFormControl-root {
        &:first-child {
          border-right: none !important;
          max-width: 150px !important;
        }
      }

      .MuiFilledInput-input {
        padding-top: 12px !important;
        padding-bottom: 13px !important;
      }

      .MuiFormLabel-root {
        transform: translate(16px, 10px) scale(1) !important;
      }

      .MuiInputLabel-shrink {
        transform: translate(16px, 1px) !important;
      }
    }
  }
}

.app-c-modal--map__cordinates {
  position: absolute;
  bottom: 16px;
  right: 16px;
  padding: 16px;
  background: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  min-width: 222px;
  @extend %flex-col;

  .cordinate-heading {
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
    color: $grey5;
  }

  .cordinate-value {
    @extend %inline-flex-vert-center;
    margin-top: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
    color: $grey1;

    .svg-icon {
      margin-right: 8px;
      color: $menu-text;
    }
  }

  .app-c-btn {
    margin-top: 16px;
  }
}

.app-l-modal--map__point {
  position: absolute;
  width: 100px;
  height: 108px;
  margin: auto;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.app-c-grant-access__capsules {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -4px;
  width: calc(100% - 4px);

  .app-c-grant-access__capsule {
    @extend %inline-flex-vert-center;
    margin: 0px 4px 12px;
    padding: 7px 12px;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: normal;
      color: $grey3;
    }

    border: 1px solid $grey6;
    border-radius: 54px;
    cursor: pointer;
    transition: all ease 0.3s;

    svg {
      margin-left: 9px;
      color: $menu-text;
      display: none;
    }

    &:hover {
      border-color: #5b5c58;
      background-color: #5b5c58;
      color: white;

      svg {
        margin-left: 9px;
        color: white;
        display: none;
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: normal;
        color: white;
      }
    }

    &.clicked {
      border-color: $access-green;
    }

    &.default {
      .icon-plus {
        display: block;
      }
    }

    &.clicked {
      .icon-close {
        display: block;
      }
    }

    &.added {
      border-color: #d3d3ce;
      background: transparent;

      span,
      svg {
        color: #d3d3ce;
      }

      .icon-check {
        display: block;
      }
    }

    &.to-delete {
      border-color: #707070;
      background-color: #5b5c58;
      cursor: default;
      color: white;

      span,
      svg {
        color: white;
      }

      .icon-close {
        display: block;
        cursor: pointer;
      }
    }
  }
}

.app-c-carousel {
  @extend %flex-col;

  svg {
    path,
    polygon {
      stroke: black !important;
    }
  }

  div {
    > &:first-child {
      // background: red;
      div {
        > &:first-child {
          // background: #EFEFEF;
          // border: 1px solid #F0ECE2;
          border-radius: 8px;

          h2 {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: $black1;
            position: absolute;
            top: -44px;
            left: 0px;
          }

          .app-c-carousel__img-wrap {
            margin-top: 20px;
            background: #efefef;
            border: 1px solid #f0ece2;
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  > div {
    overflow: unset;
  }
}

.app-c-carousel-inner-div {
  div {
    > &:first-child {
      div {
        > &:first-child {
          border-radius: 0;
        }
      }
    }
  }
}

.app-c-carousel .pan-container {
  width: 100%;
  height: 100%;
}

.app-c-carousel .pan-container > div:first-child {
  width: 100%;
  height: 100%;
}

.app-c-carousel-inner-div > div:first-child {
  width: 100%;
  height: 100%;
}

.app-c-carousel-inner-div > div:first-child > div:first-child {
  border-radius: 8px !important;
  top: 4px !important;
  box-shadow: rgba(53, 67, 93, 0.1) 0px 2px 6px !important;
}

.app-c-carousel-inner-div > div:first-child > div:first-child > div:nth-child(3),
.app-c-carousel-inner-div > div:first-child > div:first-child > div:nth-child(4) {
  display: none;
}

.app-c-filter-search {
  .MuiPaper-root {
    padding: 12px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-top: 18px;
    min-width: 236px;
    max-width: 236px;
    margin-left: -16px;
  }

  .form-control {
    background: $white;
    border: 1px solid #ebded0;
    border-radius: 8px;
    padding: 13px 20px 11px 20px;
    outline: none;
    font-size: 14px;
    line-height: normal;
    height: 40px;
    width: 100%;
  }

  .app-c-filter-search__checkbox-group {
    @extend %flex-col;
    margin: 10px 0px;
    max-height: 200px;
    overflow: auto;
  }

  .app-c-filter-search__footer {
    @extend %flex-vert-center;
    justify-content: space-evenly;
    margin-top: 20px;

    .app-c-btn {
      height: 40px;
      min-width: 47% !important;
    }
  }

  &--datepicker {
    .MuiPaper-root {
      min-width: 330px;
      max-width: 330px;
    }

    .rdrDefinedRangesWrapper {
      display: none;
    }

    .rdrDateRangePickerWrapper {
      width: 100%;

      .rdrCalendarWrapper {
        width: 100%;
      }
    }

    .rdrDateDisplayWrapper {
      background-color: transparent;
    }

    .rdrDateDisplay {
      margin: 0px;
    }

    .rdrDateDisplayItem {
      border: 1px solid #bfc3ce;
      border-radius: 8px;
      box-shadow: none;

      input {
        height: 40px;
        font-weight: 500;
        font-size: 14px;
        color: #8d9091;
        padding-top: 4px;
      }
    }

    .rdrDateDisplayItemActive {
      border-color: #e3d1bd;
    }

    .rdrMonthAndYearWrapper {
      height: auto;
    }

    .rdrNextPrevButton {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: transparent;
      margin: 0px;

      i {
        margin: 0px;
        border-width: 0;
        border-color: none;
        transform: none;
        width: 24px;
        height: 24px;
        position: relative;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    .rdrNextButton {
      i {
        &::before {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          width: 8px;
          height: 8px;
          border-bottom: 2px solid;
          border-right: 2px solid;
          transform: rotate(-45deg);
          right: 0;
          top: 0;
          margin: auto;
          left: 0;
          bottom: 0;
        }
      }
    }

    .rdrPprevButton {
      i {
        &::before {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          width: 8px;
          height: 8px;
          border-bottom: 2px solid;
          border-right: 2px solid;
          transform: rotate(135deg);
          right: 0;
          top: 0;
          margin: auto;
          left: 6px;
          bottom: 0;
        }
      }
    }

    .rdrMonthAndYearPickers {
      select {
        font-size: 13px;
        font-weight: 700;
        color: $black;
        text-align: left;

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }

    .rdrMonthName {
      color: $black;
      padding: 10px 10px 20px;
    }

    .rdrWeekDays {
      margin-bottom: 12px;
    }

    .rdrWeekDay {
      font-weight: 400;
      font-size: 12px;
      line-height: normal;
      color: rgba(60, 60, 67, 0.6);
    }

    .rdrDayToday {
      .rdrDayNumber {
        span {
          &::after {
            bottom: 2px;
            background: #e3d1bd;
          }
        }
      }
    }

    .rdrDayStartPreview,
    .rdrDayEndPreview,
    .rdrDayInPreview {
      color: #e3d1bd !important;
    }

    .rdrStartEdge,
    .rdrEndEdge {
      color: #e3d1bd !important;
    }

    .rdrSelected,
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge {
      color: #e3d1bd !important;
    }
  }

  &--number {
    .MuiPaper-root {
      min-width: 330px;
      max-width: 330px;
    }

    .app-c-grid-layout {
      flex-wrap: nowrap;
      align-items: center;
    }

    .seperator {
      padding-left: 12px;
      padding-top: 12px;
    }
  }
}

.app-c-filter-search-multiselectdropdown {
  .MuiPaper-root {
    padding: 12px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    min-width: 236px;
  }

  .form-control {
    background: $white;
    border: 1px solid #bfc3ce;
    border-radius: 8px;
    padding: 13px 20px 11px 20px;
    outline: none;
    font-size: 14px;
    line-height: normal;
    height: 40px;
    width: 100%;
  }

  .app-c-filter-search__checkbox-group {
    @extend %flex-col;
    margin: 10px 0px;
    max-height: 200px;
    overflow: auto;
  }

  .app-c-filter-search__footer {
    @extend %flex-vert-center;
    justify-content: space-evenly;
    margin-top: 20px;

    .app-c-btn {
      height: 40px;
      min-width: 47% !important;
    }
  }

  &--datepicker {
    .MuiPaper-root {
      min-width: 330px;
      max-width: 330px;
    }

    .rdrDefinedRangesWrapper {
      display: none;
    }

    .rdrDateRangePickerWrapper {
      width: 100%;

      .rdrCalendarWrapper {
        width: 100%;
      }
    }

    .rdrDateDisplayWrapper {
      background-color: transparent;
    }

    .rdrDateDisplay {
      margin: 0px;
    }

    .rdrDateDisplayItem {
      border: 1px solid #bfc3ce;
      border-radius: 8px;
      box-shadow: none;

      input {
        height: 40px;
        font-weight: 500;
        font-size: 14px;
        color: #8d9091;
        padding-top: 4px;
      }
    }

    .rdrDateDisplayItemActive {
      border-color: #e3d1bd;
    }

    .rdrMonthAndYearWrapper {
      height: auto;
    }

    .rdrNextPrevButton {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: transparent;
      margin: 0px;

      i {
        margin: 0px;
        border-width: 0;
        border-color: none;
        transform: none;
        width: 24px;
        height: 24px;
        position: relative;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    .rdrNextButton {
      i {
        &::before {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          width: 8px;
          height: 8px;
          border-bottom: 2px solid;
          border-right: 2px solid;
          transform: rotate(-45deg);
          right: 0;
          top: 0;
          margin: auto;
          left: 0;
          bottom: 0;
        }
      }
    }

    .rdrPprevButton {
      i {
        &::before {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          width: 8px;
          height: 8px;
          border-bottom: 2px solid;
          border-right: 2px solid;
          transform: rotate(135deg);
          right: 0;
          top: 0;
          margin: auto;
          left: 6px;
          bottom: 0;
        }
      }
    }

    .rdrMonthAndYearPickers {
      select {
        font-size: 13px;
        font-weight: 700;
        color: $black;
        text-align: left;

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }

    .rdrMonthName {
      color: $black;
      padding: 10px 10px 20px;
    }

    .rdrWeekDays {
      margin-bottom: 12px;
    }

    .rdrWeekDay {
      font-weight: 400;
      font-size: 12px;
      line-height: normal;
      color: rgba(60, 60, 67, 0.6);
    }

    .rdrDayToday {
      .rdrDayNumber {
        span {
          &::after {
            bottom: 2px;
            background: #e3d1bd;
          }
        }
      }
    }

    .rdrDayStartPreview,
    .rdrDayEndPreview,
    .rdrDayInPreview {
      color: #e3d1bd !important;
    }

    .rdrStartEdge,
    .rdrEndEdge {
      color: #e3d1bd !important;
    }

    .rdrSelected,
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge {
      color: #e3d1bd !important;
    }
  }

  &--number {
    .MuiPaper-root {
      min-width: 330px;
      max-width: 330px;
    }

    .app-c-grid-layout {
      flex-wrap: nowrap;
      align-items: center;
    }

    .seperator {
      padding-left: 12px;
      padding-top: 12px;
    }
  }
}

.app-l-rating-selection-wrap {
  background: $white;
  border-radius: 8px;
  @extend %flex-vert-center;

  .app-l-rating-selection {
    padding: 16px 14px 12px;
    border-right: 1px solid $grey8;

    .MuiRating-root {
      font-size: 18px;
    }
  }

  .app-l-rating-text {
    padding: 16px 20px;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $grey3;
    }
  }

  .app-l-rating-nav-btn {
    margin-left: auto;
    margin-right: 10px;

    .icon-chevron-right {
      width: 7px;
      height: 18px;
    }
  }
}

// Activity log slide in.

.app-l-activity-log {
  position: fixed;
  right: 0px;
  background: $white;
  box-shadow: -2px 4px 22px rgba(0, 0, 0, 0.08);
  transition: all ease 0.3s;
  transform: translateX(100%);

  &.active {
    transform: translateX(0%);
  }
}

.clear-filter-btn {
  width: 36px;
  height: 36px;
  border: 1px solid #bfc3ce !important;
  border-radius: 8px !important;
  color: #6b6a66 !important;
  padding: 8px 6px 7px 6px !important;
  background: $white !important;
  margin-left: auto !important;
  margin-right: 4px !important;
}

.weapon-status-btn {
  position: absolute !important;
  right: 8px;
  left: auto;
  top: 0px;
  bottom: 0;
  margin: auto !important;
  width: 32px;
  height: 32px;

  .icon-unservicable {
    color: #bc5151;
  }

  .icon-import-round {
    color: #272c37;
  }

  + .weapon-status-btn {
    right: 32px;
  }
}

// Three state toggle.

.app-c-three-state-toggle__wrap {
  @extend %inline-flex-vert-center;
}

.app-c-three-state-toggle__text {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #6b6a66;

  &.not-received {
    color: #e26d42;
    margin-right: 8px;
  }

  &.received {
    color: #39c89d;
    margin-left: 8px;
  }
}

.app-c-three-state-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dadada;
  border-radius: 8px;
  width: 54px;
  height: 12px;
  .toggle-button {
    height: 20px;
    width: 20px;
    appearance: none;
    border-radius: 50%;
    opacity: 0;
    margin: 0px;
    flex-shrink: 0;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    transition: all ease 0.3s;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 9px;
      height: 9px;
      background: #f2f2f2;
      border-radius: 50%;
      left: 6px;
      top: 5px;
    }
  }
  .toggle-button:hover {
    cursor: pointer;
  }

  #one {
    background: #e26d42;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    transform: rotate(0.27deg);
    &::before {
      width: 8px;
      height: 8px;
      background: #ffffff;
      opacity: 0.5;
      left: 5.5px;
      top: 6px;
    }
  }

  #two {
    opacity: 1;
    background: #ffffff;
  }

  #three {
    background: #39c89d;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    transform: rotate(0.27deg);
    &::before {
      width: 8px;
      height: 8px;
      background: #ffffff;
      opacity: 0.5;
      left: 5.5px;
      top: 6px;
    }
  }
  .lock {
    &::before {
      background: url("../../images/lock.svg") !important;
      width: 9px !important;
      height: 11px !important;
      top: 4px !important;
      background-repeat: no-repeat !important;
      border-radius: 0px !important;
      left: 6px !important;
    }
  }
}
