@use "../../../assets/scss/abstracts/utilities" as *;
@use "../../../assets/scss/abstracts/palette" as *;
@use "../../../assets/scss/abstracts/typography" as *;
@use "../../../assets/scss/abstracts/variables" as *;

.app-c-datepicker-field {
    .MuiTextField-root {
        .MuiInputBase-root {
            .MuiInputAdornment-root {
                .MuiIconButton-root {
                    padding: 0px 8px 0px 0px;
                    .MuiSvgIcon-fontSizeMedium {
                        width: 20px;
                        height: 24px;
                        color: $menu-text;
                    }
                }
            }
        }
    }
}
.MuiPickersPopper-root {
    .MuiCalendarOrClockPicker-root {
        .MuiCalendarPicker-root {
            .MuiPickersCalendarHeader-root {
                .MuiPickersCalendarHeader-labelContainer {
                    color: $black;
                    font-size: 14px;
                    font-weight: 500;
                    font-family: $font-primary;
                }
                .MuiPickersArrowSwitcher-root {
                    .MuiIconButton-root {
                        svg {
                            width: 20px;
                            height: 20px;
                            color: $black;
                        }
                    }
                }
            }
            .MuiPickersFadeTransitionGroup-root {
                .MuiDayPicker-header {
                    .MuiTypography-caption {
                        &.MuiDayPicker-weekDayLabel {
                            color: $black;
                            font-size: 14px;
                            font-weight: 500;
                            font-family: $font-primary;
                        }
                    }
                }
                .MuiDayPicker-slideTransition {
                    min-height: 220px;
                    .MuiDayPicker-monthContainer {
                        .MuiDayPicker-weekContainer {
                            .MuiPickersDay-root{
                                color: $black;
                                font-size: 14px;
                                font-weight: 500;
                                font-family: $font-primary;
                                &.MuiPickersDay-today {
                                    color: $black;
                                    font-size: 14px;
                                    font-weight: 600;
                                    font-family: $font-primary;
                                    background-color: $brand-primary;
                                    border: 1px solid $black;
                                }
                                &.Mui-selected {
                                    background-color: $brand-primary;
                                }
                            }
                        }
                    }
                }
                .MuiYearPicker-root {
                    .PrivatePickersYear-root {
                        .PrivatePickersYear-yearButton {
                            color: $black;
                            font-size: 14px;
                            font-weight: 500;
                            font-family: $font-primary;
                            &.Mui-selected {
                                color: $black;
                                font-weight: 600;
                                background-color: $brand-primary;
                            }
                        }
                    }
                }

                .MuiMonthPicker-root {
                    .PrivatePickersMonth-root {
                       
                            color: $black;
                            font-size: 14px;
                            font-weight: 500;
                            font-family: $font-primary;
                            &.Mui-selected {
                                color: $black;
                                font-weight: 600;
                                background-color: $brand-primary;
                            }
                        
                    }
                }
            }
        }
    }
}
.MuiDialog-root {
    .MuiCalendarOrClockPicker-root {
        .MuiPickersToolbar-root {
            display: none;
        }
        .MuiCalendarPicker-root {
            .MuiPickersCalendarHeader-root {
                .MuiPickersCalendarHeader-labelContainer {
                    color: $black;
                    font-size: 14px;
                    font-weight: 500;
                    font-family: $font-primary;
                }
                .MuiPickersArrowSwitcher-root {
                    .MuiIconButton-root {
                        svg {
                            width: 20px;
                            height: 20px;
                            color: $black;
                        }
                    }
                }
            }
            .MuiPickersFadeTransitionGroup-root {
                .MuiDayPicker-header {
                    .MuiTypography-caption {
                        &.MuiDayPicker-weekDayLabel {
                            color: $black;
                            font-size: 14px;
                            font-weight: 500;
                            font-family: $font-primary;
                        }
                    }
                }
                .MuiDayPicker-slideTransition {
                    min-height: 220px;
                    .MuiDayPicker-monthContainer {
                        .MuiDayPicker-weekContainer {
                            .MuiPickersDay-root{
                                color: $black;
                                font-size: 14px;
                                font-weight: 500;
                                font-family: $font-primary;
                                &.MuiPickersDay-today {
                                    color: $black;
                                    font-size: 14px;
                                    font-weight: 600;
                                    font-family: $font-primary;
                                    background-color: $brand-primary;
                                    border: 1px solid $black;
                                }
                                &.Mui-selected {
                                    background-color: $brand-primary;
                                }
                            }
                        }
                    }
                }
                .MuiYearPicker-root {
                    .PrivatePickersYear-root {
                        .PrivatePickersYear-yearButton {
                            color: $black;
                            font-size: 14px;
                            font-weight: 500;
                            font-family: $font-primary;
                            &.Mui-selected {
                                color: $black;
                                font-weight: 600;
                                background-color: $brand-primary;
                            }
                        }
                    }
                }
            }
        }
    }
    .MuiDialogActions-root {
        .MuiButton-root {
            color:$brand-primary;
            font-size: 14px;
            font-weight: 500;
            font-family: $font-primary;
        }
    }
}