@use "../../assets/scss/abstracts/" as *;

.app-l-sidebar {
  position: relative;
  z-index: 1001;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 72px;
  background: $body-bg;
  @extend %flex-col;
  flex-shrink: 0;
  box-shadow: 1px 0px 0px $header-border-color;
  transition: all ease-in-out 0.3s;

  @media (max-width: $screen-ipad-landscape) {
    position: fixed;
  }

  .app-l-sidebar__logo {
    padding: 13px 10px 0px;
    text-align: center;
    // margin-bottom: 60px;
    height: 107px;
    visibility: visible;
    opacity: 1;
    transition: visibility ease-in-out 0.3s, opacity ease-in-out 0.3s;

    .fgs-logo {
      max-width: 51px;
      transition: all ease-in-out 0.3s;
    }

    .sna-logo,
    .spf-logo {
      max-width: 42px;
      transition: all ease-in-out 0.3s;
    }
  }

  .app-l-sidebar__menu {
    // padding: 14px;
    @extend %flex-col;
    min-height: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 180px);
    // margin-right: 5px;
    // margin-top: 44px;
  }

  ul {
    @extend %flex-col;
    min-width: 44px;

    li {
      @extend %flex-col;
      // position: relative;

      a {
        padding: 11px 12px 11px 26px;
        // border-radius: 8px;
        font-size: 0;
        line-height: normal;
        color: $menu-text;
        text-decoration: none;
        @extend %inline-flex-vert-center;
        cursor: pointer;
        width: 100%;
        transition: all ease-in-out 0.3s;

        &:hover {
          // background: $brand-primary;

          .menu-icon {
            color: $menu-text-active;
          }

          .menu-text {
            color: $menu-text-active;
          }
        }
      }

      // +li {
      //     margin-top: 4px;
      // }

      &.active {
        > a {
          background: $brand-primary;

          .menu-icon {
            color: $menu-text-active;
          }

          .menu-text {
            color: $menu-text-active;
          }
        }
      }

      .MuiTooltip-popper {
        .MuiTooltip-tooltip {
          margin-bottom: -4px !important;
        }
      }
    }
  }

  .menu-text {
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
    color: $menu-text;
    white-space: nowrap;
    margin-left: 0px;
    visibility: hidden;
    opacity: 0;
    width: 0px;
    transition: visibility ease-in-out 0.3s, opacity ease-in-out 0.3s, margin-left ease-in-out 0.3s;
  }

  .menu-count {
    padding: 2px 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $menu-text;
    background: #ebded0;
    border-radius: 48px;
    margin-left: auto;
    visibility: hidden;
    opacity: 0;
    width: 46px;
    height: 19px;
    text-align: center;
    transition: visibility ease-in-out 0.3s, opacity ease-in-out 0.3s, margin-left ease-in-out 0.3s;
  }

  .app-c-sidebar-toggle {
    position: absolute !important;
    left: 26px;
    right: auto;
    // background: $body-bg !important;
    // border: 1px solid $header-border-color !important;
    // border-radius: 0px 8px 8px 0px !important;
    top: 72px;
    padding: 0px !important;
    line-height: normal;
    z-index: 1;
    transition: all ease 0.3s;
  }

  .app-c-menu__dropdown-toggle {
    margin-left: auto !important;
    padding: 0px !important;
    width: 16px;
    height: 16px;
    // position: absolute;
    right: 8px;
    transition: all ease 0.3s;

    @media (max-width: $screen-xxl) {
      right: 1px;
    }
  }

  .app-l-sidebar__menu__bottom {
    margin-top: auto;
    @extend %flex-vert-col;
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: auto;
    transform: translate(0px, 115px);
    z-index: 1;
    transition: all ease 0.3s;

    ul {
      width: 100%;
    }

    .profile-menu {
      margin-top: 5px;
      background: $profile-menu-bg;
      padding: 12px 15px;
      border-radius: 10px 10px 0px 0px;
      width: 100%;
      position: relative;
      z-index: 1;
      cursor: pointer;

      a {
        @extend %inline-flex-vert-center;
        width: 100%;
        text-decoration: none;
        padding: 6px 5px;

        img {
          width: 27px;
          height: 27px;
          margin-left: 3px;
          border-radius: 50%;
        }
      }

      ul {
        li {
          a {
            padding: 6px 13px;
          }
        }
      }

      .app-c-menu__dropdown-toggle {
        visibility: hidden;
        opacity: 0;
        width: 0px;
      }

      .profile-sub-menu {
        border-top: 1px dashed $menu-text;
        padding-top: 6px;
        margin-top: 5px;
      }
    }

    &.active {
      transform: translate(0px, 0px);

      .app-c-menu__dropdown-toggle {
        transform: rotate(180deg);
      }
    }
  }

  .app-l-sub-menu-blk {
    position: absolute;
    right: -220px;
    // background: $grid-header-bg;
    background: $body-bg;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.37);
    max-height: 0;
    visibility: visible;
    opacity: 1;
    min-width: 220px;
    overflow: hidden;
    transform: translateY(40px);
    z-index: 1001;
    transition: all ease 0.3s;

    &::before {
      content: "";
      position: absolute;
      left: 0px;
      width: 2px;
      background: $brand-primary;
      height: 100%;
      top: 0px;
      bottom: 0px;
      margin: auto;
      z-index: 1;
      transition: width ease 0.2s;
    }

    ul {
      position: relative;
    }

    a {
      padding: 11px 8px 11px 24px;

      .menu-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $menu-text;
        position: relative;
        margin-left: 0;
        opacity: 1;
        visibility: visible;
        transition: all ease 0.3s;
      }

      // .menu-count {
      //     padding: 2px 6px;
      //     font-weight: 500;
      //     font-size: 12px;
      //     line-height: 16px;
      //     color: $menu-text;
      //     background: #FFFFFF;
      //     border-radius: 48px;
      //     margin-left: auto;
      //     transition: all ease 0.3s;
      // }

      &:hover {
        background: $grid-header-bg;

        .menu-text {
          color: $menu-text-active;

          &::before {
            width: 3px;
          }
        }

        .menu-count {
          background: $white;
          color: $menu-text-active;
        }
      }
    }

    li {
      &.active {
        a {
          background: $grid-header-bg;

          .menu-text {
            color: $menu-text-active;

            &::before {
              width: 3px;
            }
          }

          .menu-count {
            background: $white;
            color: $menu-text-active;
          }
        }
      }
    }

    &.active {
      max-height: 200px;
      //   visibility: visible;
      //   opacity: 1;
      overflow: auto;
      transform: translateY(0px);
      transition: all ease 0.3s;

      .menu-count {
        visibility: visible;
        opacity: 1;
      }
    }

    @media (max-width: $screen-md) {
      position: absolute;
      right: -196px;

      a {
        .menu-text {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .app-l-language-selection-wrap {
    @extend %inline-flex-vert-center;
    width: 100%;
    text-decoration: none;
    padding: 6px 13px;
    color: $menu-text;
  }

  .language-selection-blk {
    @extend %inline-flex-vert-center;
    visibility: hidden;
    opacity: 0;
    width: 0px;
    transition: visibility ease-in-out 0.3s, opacity ease-in-out 0.3s, margin-left ease-in-out 0.3s;

    span {
      display: inline-block;
      position: relative;
      font-weight: 500;
      font-size: 14px;
      line-height: normal;
      color: $menu-text;

      + span {
        margin-left: 16px;
      }

      &::before {
        content: "";
        position: absolute;
        bottom: -3px;
        top: auto;
        width: 100%;
        height: 0px;
        background: transparent;
        transition: all ease 0.3s;
      }

      &:hover,
      &.active {
        color: $black;

        &::before {
          height: 2px;
          background-color: $brand-primary;
        }
      }
    }
  }

  &.active {
    // width: 278px;
    width: 236px;

    .app-l-sidebar__logo {
      // margin-bottom: 10px;
      visibility: visible;
      opacity: 1;

      .fgs-logo {
        max-width: 85px;
      }

      .sna-logo,
      .spf-logo {
        max-width: 62px;
      }
    }

    li {
      a {
        width: 100%;
        justify-content: flex-start;
      }
    }

    .menu-text {
      margin-left: 16px;
      visibility: visible;
      opacity: 1;
      width: 100%;
    }

    .menu-count {
      visibility: visible;
      opacity: 1;
    }

    .profile-menu {
      .app-c-menu__dropdown-toggle {
        // visibility: visible;
        opacity: 1;
        width: 16px;
      }
    }

    .app-c-sidebar-toggle {
      // background: $sidebar-toggle-active !important;
      // transform: matrix(-1, 0, 0, 1, 0, 0);
      // border-color: $sidebar-toggle-active !important;
      top: 28px;
    }

    .app-c-menu__dropdown-toggle {
      // right: 24px;
    }

    // .app-l-sub-menu-blk {
    //     position: unset;
    //     right: unset;
    //     background: transparent;

    //     ul {
    //         &::before {
    //             content: '';
    //             position: absolute;
    //             left: 20px;
    //             width: 1px;
    //             background: rgba(66, 55, 31, 0.1);
    //             height: 100%;
    //         }

    //         li {
    //             a {
    //                 padding: 5px 20px;

    //                 .menu-text {
    //                     padding-left: 26px;
    //                 }

    //                 &:hover {
    //                     .menu-text {
    //                         &::before {
    //                             width: 3px;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    .language-selection-blk {
      visibility: visible;
      opacity: 1;
      width: 100%;
      margin-left: 16px;
    }
  }
}