@use "../abstracts" as *;
@use "../framework/button" as *;

.form-group {
  position: relative;

  .form-label {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #968a7e;

    + .form-value {
      margin-top: 3px;
      display: block;
    }
  }

  .form-value {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $brand-black;
  }

  &--column {
    @extend %flex-col;
  }

  &--textarea {
    .MuiFilledInput-input {
      height: 68px !important;
    }
  }
  &--signature {
    .MuiFilledInput-input {
      height: 68px !important;
      font-size: 24px !important;
      font-weight: 500 !important;
    }
  }
}

.input-group {
  @extend %flex;

  .MuiFormControl-root {
    width: 50%;

    &:first-child {
      border-radius: 6px 0px 0px 6px !important;
    }

    &:last-child {
      border-radius: 0px 6px 6px 0px !important;
      border-left: none !important;
    }
  }
}

.error-text {
  background: $danger-red;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 20%);
  border-radius: 4px;
  color: $white;
  padding: 4px 10px;
  position: absolute;
  left: -2px;
  bottom: 45px;
  font-weight: 400;
  font-size: 12px;
  z-index: 1;
  visibility: 0;
  opacity: 0;
  transform: translate(0px, 15px);
  transition: all ease 0.3s;

  &::after {
    content: "";
    position: absolute;
    // bottom: 0px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 7px solid $danger-red;
    left: 19px;
    bottom: -7px;
  }
}

.error-icon {
  visibility: hidden;
  opacity: 0;
  width: 0;
  transition: all ease 0.3s;
}

// Build classes
.MuiFilledInput-root {
  background-color: $white !important;
  font-family: $body-font !important;
  border-radius: 6px !important;
  padding-left: 0px !important;

  &::before,
  &::after {
    display: none;
  }

  .icon-search {
    width: 16px;
    height: 16px;
    color: $black;
  }
}

.MuiFormControl-root {
  font-family: $body-font !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: normal !important;
  color: $brand-black !important;
  border: 1px solid #ebded0 !important;
  border-radius: 6px !important;
  background-color: transparent !important;
  // padding-right: 20px !important;
  width: 100%;

  &::before,
  &::after {
    display: none;
  }
}

.MuiFormLabel-root {
  font-family: $body-font !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: normal !important;
  color: #968a7e !important;
  transform: translate(16px, 17px) scale(1) !important;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, font-size 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.MuiInputLabel-shrink {
  font-family: $body-font !important;
  transform: translate(16px, 8px) !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: normal !important;
}

.text-field {
  .MuiFilledInput-input {
    font-family: $body-font !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: normal !important;
    color: $brand-black !important;
    padding-top: 28px !important;
    padding-right: 16px !important;
    padding-bottom: 10px !important;
    padding-left: 16px !important;
    height: 13px !important;
    border-radius: 6px !important;

    &:focus {
      background-color: $input-focus !important;
    }
  }
}

.app-c-datepicker-field {
  .MuiFilledInput-input {
    font-family: $body-font !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: normal !important;
    color: $brand-black !important;
    padding-top: 28px !important;
    padding-right: 16px !important;
    padding-bottom: 10px !important;
    padding-left: 16px !important;
    height: 13px !important;
    border-radius: 6px !important;

    &:focus {
      background-color: $input-focus !important;
    }
  }
}

.text-area {
  .MuiFilledInput-input {
    font-family: $body-font !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: normal !important;
    color: $brand-black !important;
    padding-top: 28px !important;
    padding-right: 16px !important;
    padding-bottom: 10px !important;
    padding-left: 16px !important;
    border-radius: 6px !important;

    &:focus {
      background-color: $input-focus !important;
    }
  }
}

.MuiAutocomplete-root {
  .MuiFormControl-root {
    div {
      padding-top: 0 !important;
    }
  }
}

.MuiAutocomplete-popupIndicator {
  transform: none !important;
  padding-top: 12px !important;
}

div.MuiFilledInput-input {
  &:focus {
    background-color: transparent !important;
  }
}

.MuiSelect-select {
  min-height: 0 !important;
  padding-top: 24px !important;
  padding-bottom: 14px !important;
}

.MuiPopover-root {
  .MuiList-root {
    max-height: 280px;
    overflow: auto;
  }
  .MuiButtonBase-root {
    font-family: $body-font !important;
    font-size: 14px !important;
  }
}

.left-icon {
  .MuiInputBase-root {
    flex-direction: row-reverse;
  }

  .MuiInputAdornment-root {
    margin: 0px;
  }
}

.has-error {
  &:hover {
    .error-text {
      visibility: 1;
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }

  .MuiFilledInput-input {
    padding-left: 10px !important;
  }

  .MuiInputAdornment-root {
    margin-left: 16px;
    margin-top: 4px;
  }

  .MuiFormLabel-root {
    transform: translate(44px, 16px) !important;
  }

  .MuiInputLabel-shrink {
    transform: translate(44px, 8px) !important;
  }

  .MuiFormControl-root {
    border-color: $danger-red !important;
  }

  .icon-info {
    color: $danger-red;
  }

  .error-icon {
    visibility: visible;
    opacity: 1;
    width: 18px;
  }
}

.MuiInputAdornment-positionEnd {
  cursor: pointer;
}
.MuiInputBase-adornedEnd {
  .MuiFilledInput-input {
    border-radius: 6px 0px 0px 6px !important;
  }
}
.MuiAutocomplete-root {
  .MuiInputBase-root {
    padding-left: 0px !important;
    padding-right: 36px !important;
  }
  .MuiInputBase-input {
    border-radius: 6px 0px 0px 6px !important;
  }
  .MuiIconButton-root {
    height: 28px !important;
    width: 28px !important;
    padding-top: 5px !important;
  }
  .MuiInputAdornment-root {
    margin-left: 2px !important;
    .error-icon {
      margin-left: 14px;
    }
  }
}
.MuiAutocomplete-popper {
  font-size: 14px !important;
}
// Toggle switch
.app-c-toggle-switch {
  @extend %inline-flex-vert-center;
  .app-c-toggle-switch__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8d9091;
    &.active {
      font-weight: 500;
      color: #111726;
    }
  }
  .MuiSwitch-root {
    width: 32px !important;
    height: 16.5px !important;
    padding: 0px !important;
    margin: 0px 8px;
  }
  .MuiSwitch-thumb {
    width: 12px !important;
    height: 12px !important;
    box-shadow: none !important;
    background-color: $white !important;
  }
  .MuiSwitch-switchBase {
    padding: 2px !important;
  }
  .MuiSwitch-track {
    background: #bfc3ce !important;
    opacity: 1 !important;
    border-radius: 40px !important;
  }
  .Mui-checked {
    + .MuiSwitch-track {
      background-color: $brand-primary !important;
    }
  }
}
