@use "../abstracts" as *;

.icon-fill-transparent {
  fill: transparent;
}

.svg-icon {
  overflow: visible;
  transition: color $animation-type1 $animation-duration-3;
}

.icon-fill {
  fill: currentColor;
}

.icon-stroke {
  stroke: currentColor;
}

.icon-fill-white,
.icon-stroke-white {
  fill: $white;
}

.icon-google {
  width: 18px;
  height: 18px;
}

.icon-arrow-right {
  width: 10px;
  height: 9px;
}

.icon-locator {
  width: 16px;
  height: 20px;
}

.icon-chart {
  width: 15px;
  height: 15px;
}

.icon-arrow-left {
  width: 13px;
  height: 13px;
}

.icon-import {
  width: 17px;
  height: 15px;
}

.icon-transfers {
  width: 16px;
  height: 15px;
}

.icon-user-management {
  width: 18px;
  height: 17px;
}

.icon-role-management {
  width: 18px;
  height: 18px;
}

.icon-notifications {
  width: 16px;
  height: 18px;
}

.icon-chevron-left {
  width: 5px;
  height: 10px;
}

.icon-chevron-right {
  width: 5px;
  height: 12px;
}

.icon-chevron-down {
  width: 10px;
  height: 6px;
}

.icon-download {
  width: 15px;
  height: 20px;
}

.icon-chevron-up {
  width: 10px;
  height: 6px;
}

.icon-edit,
.icon-move-right {
  width: 18px;
  height: 18px;
}

.icon-img-edit {
  width: 16px;
  height: 16px;
}

.icon-move-right {
  position: relative;
  top: 4px;
  left: 5px;
}

.icon-filter {
  width: 15px;
  height: 15px;
}

.icon-enter {
  width: 12px;
  height: 12px;
}

.icon-search {
  width: 16px;
  height: 16px;
}

.icon-sort {
  width: 8px;
  height: 12px;
}

.icon-preference {
  width: 15px;
  height: 15px;
}

.icon-personal-details {
  width: 12px;
  height: 14px;
}

.icon-signout {
  width: 13px;
  height: 12px;
}

.icon-image-upload {
  width: 20px;
  height: 20px;
}

.icon-lock {
  width: 12px;
  height: 15px;
}

.icon-info {
  width: 18px;
  height: 18px;
}

.icon-export {
  width: 15px;
  height: 14px;
}

.icon-personnel {
  width: 16px;
  height: 20px;
}

.icon-import-new {
  width: 18px;
  height: 19px;
}

.icon-unit {
  width: 16px;
  height: 20px;
}

.icon-armoury {
  width: 16px;
  height: 20px;
}

.icon-weapons {
  width: 18px;
  height: 14px;
}

.icon-ammunition {
  width: 16px;
  height: 16px;
}

.icon-spot-check {
  width: 16px;
  height: 18px;
}

.icon-assign {
  width: 15px;
  height: 14px;
}

.icon-plus {
  width: 12px;
  height: 12px;
}

.icon-check {
  width: 13px;
  height: 10px;
}

.icon-close {
  width: 10px;
  height: 10px;
}

.icon-datepicker {
  width: 20px;
  height: 20px;
}

.icon-info-outline {
  width: 16px;
  height: 16px;
}

.icon-delete {
  width: 12px;
  height: 14px;
}

.icon-refresh {
  width: 18px;
  height: 16px;
}

.icon-map {
  width: 18px;
  height: 17px;
}

.icon-location-info {
  width: 17px;
  height: 17px;
}

.icon-expand {
  width: 12px;
  height: 12px;
}

.icon-minimize {
  width: 12px;
  height: 12px;
}

.icon-sort-asc {
  width: 9px;
  height: 6px;
}

.icon-sort-desc {
  width: 9px;
  height: 6px;
}

.icon-assigned {
  width: 15px;
  height: 8px;
}

.icon-view-image {
  width: 16px;
  height: 16px;
}

.icon-hamburger {
  width: 20px;
  height: 16px;
}

.icon-copy {
  width: 14px;
  height: 14px;
}

.icon-minus {
  width: 14px;
  height: 2px;
}

.icon-map-locator {
  width: 20px;
  height: 20px;
  margin-left: 3px;
}

.icon-activity-log {
  width: 14px;
  height: 14px;
}

.icon-logout {
  width: 13px;
  height: 13px;
}

.icon-language-selection {
  width: 16px;
  height: 15px;
}
.icon-unservicable {
  width: 16px;
  height: 16px;
}
.icon-import-round {
  width: 16px;
  height: 16px;
}
.icon-frequency {
  width: 19px;
  height: 13px;
}
.icon-star {
  width: 18px;
  height: 17px;
}
.icon-qa {
  width: 16px;
  height: 20px;
}
.icon-users-outline {
  width: 18px;
  height: 17px;
}
.icon-import-outline {
  width: 20px;
  height: 20px;
}
.icon-check-round-fill {
  width: 13px;
  height: 13px;
}
.icon-edit-round-fill {
  width: 13px;
  height: 13px;
}
.icon-clear-filter {
  width: 22px;
  height: 23px;
}

.menu-icon {
  // width: 16px;
  fill: $white;
  margin-left: 1px;
}
