@use "../abstracts/utilities" as *;
@use "../abstracts/typography" as *;
@use "../abstracts/palette" as *;

.app-c-grid {
  .MuiPaper-root {
    background: $white;
    border: 1px solid $grey7;
    border-radius: 6px;
    box-shadow: none;
    height: 100%;
  }

  .app-c-grid__sort {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: all ease 0.3s;

    .svg-icon {
      color: $menu-text;
    }

    .icon-sort-desc {
      margin-top: 2px;
    }

    .MuiButtonBase-root {
      padding: 0px;

      .MuiTouchRipple-root {
        display: none;
      }

      &:hover {
        background-color: transparent;
      }

      &.active {
        background-color: transparent;

        .svg-icon {
          color: $grey3;
        }
      }

      &:not(.active) {
        opacity: 0.5;
      }
    }

    &.active {
      opacity: 1;
    }
  }

  .grid-sort-icon {
    margin-left: auto;
    color: $menu-text;
  }

  thead {
    tr {
      th {
        font-family: $body-font;
        padding: 12px 14px;
        font-size: 12px;
        font-weight: 600;
        line-height: 17px;
        color: $grey3;
        background: $grid-header-bg;
        border-right: 1px solid $grid-border;
        border-bottom-color: $grid-border;
        text-align: left;

        min-width: max-content;

        span {
          width: calc(100% - 20px);
          color: $grey3 !important;
        }

        &.active {
          .app-c-grid__sort {
            opacity: 1;
          }
        }

        &:hover {
          .app-c-grid__sort {
            opacity: 1;
          }
        }

        .app-c-grid__header-filter-btn {
          padding: 0px;
          margin-right: 8px;

          .svg-icon {
            width: 12px;
            height: 12px;
          }

          &:hover {
            background-color: transparent;

            .svg-icon {
              color: $grey3;
            }
          }

          &.filter-applied {
            color: $brand-primary;
          }

          .MuiTouchRipple-root {
            display: none;
          }
        }
      }
    }
  }

  tbody {
    tr {
      th,
      td {
        font-family: $body-font;
        padding: 6px 16px;
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        color: $grid-content;
        border-color: $grid-border;
        text-align: left;
        height: 48px;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;

        .app-c-btn--secondary {
          height: 32px;
        }
        .app-c-btn--remove {
          height: 32px;
        }
      }

      &:last-child {
        th,
        td {
          border-bottom: 1px solid #dedbd0;
        }
      }

      td {
        position: relative;
      }
    }
  }

  .app-c-grid__operations {
    @extend %flex-vert-center;
    margin-bottom: 16px;

    .app-c-grid__operations__left {
      margin-right: auto;
      max-width: 345px;

      form {
        position: relative;
        min-width: 345px;

        .form-control {
          background: #ffffff;
          border: 1px solid #e8daca;
          border-radius: 8px;
          padding: 12px 100px 10px 48px;
          width: 100%;
          outline: none;
          font-size: 14px;
          line-height: normal;
        }

        .vertical-line {
          position: absolute;
          left: 35px;
          top: 4px;
          height: 32px;
          width: 1px;
          background: #e2e2de;
        }

        .search-icon {
          position: absolute;
          left: 12px;
          top: 12px;
          color: #6b6a66;
        }

        .search-text {
          font-weight: 500;
          font-size: 14px;
          line-height: normal;
          color: $grey5;
          position: absolute;
          left: 41px;
          top: 11px;
        }
      }
    }
  }

  .app-c-grid__pagination {
    @extend %flex-vert-center;
    margin-top: 12px;

    .app-c-grid__pagination__left {
      margin-right: auto;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $menu-text;
      }

      .form-control {
        width: 62px !important;
        margin: 0px 8px 0px 0px;
        padding: 5px 6px 5px 6px !important;
        border-radius: 6px !important;
        border: 1px solid #bfc3ce;
        outline: none;

        option {
          padding: 5px 0px;
        }
      }
    }

    .app-c-grid__pagination__right {
      .MuiPaginationItem-root {
        min-width: 32px;
        height: 29px;
        border: 1px solid #e5e5e5;
        border-radius: 6px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $menu-text;

        &.Mui-selected {
          background: #e3d1bd;
          border-color: #e3d1bd;
          color: $white;
        }

        &.MuiPaginationItem-ellipsis {
          border: none;
          line-height: 32px;
        }

        &.MuiPaginationItem-firstLast,
        &.MuiPaginationItem-previousNext {
          border: none;

          svg {
            font-size: 20px;
          }
        }
      }
    }
  }
}
