@use "../abstracts" as *;
@use "../framework/button" as *;

.app-c-breadcrumb-blk {
    margin-top: 0px;
    padding: 2px 4px;
    border: 1px solid $grey6;
    border-radius: 2px;
    margin-left: 24px;
    transition: all ease 0.3s;
    display: flex;
    align-items: center;
    max-width: 20px;
    height: 20px;
    // transition: all ease 0.3s;

    .app-c-breadcrumb__hidden {
        visibility: hidden;
        opacity: 0;
        white-space: nowrap;
        width: 0px;
        display: flex;
        align-items: center;
        transition: all ease 0.3s;

        .icon-chevron-right {
            margin: 0px 8px;
            color: $grey5;
        }
        a {
            text-decoration: none;
        }
        span {
            font-weight: 400;
            font-size: 12px;
            line-height: normal;
            color: $grey10;

            &.active {
                color: $grey3;
                font-weight: 600;
            }
        }
    }

    .app-c-breadcrumb__trigger {
        visibility: visible;
        opacity: 1;
        transition: all ease 0.3s;
    }

    &:hover {
        padding: 2px 8px;
        max-width: 400px;

        .app-c-breadcrumb__trigger {
            visibility: hidden;
            opacity: 0;
            white-space: nowrap;
            width: 0px;
        }

        .app-c-breadcrumb__hidden {
            visibility: visible;
            opacity: 1;
            width: 100%;
        }

    }
}