@use "../abstracts" as *;
@use "../abstracts/palette" as *;

.app-c-tab {
  @extend %flex-col;
  height: 100%;
  min-height: 0;

  .app-c-tab__menu {
    // border-bottom: 1px solid $header-border-color;

    .MuiTabs-root {
      min-height: 0;
    }

    .MuiButtonBase-root {
      font-family: $body-font !important;
      padding: 0px 0px 12px 0px;
      min-height: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      color: #968a7e;
      text-transform: capitalize;
      min-width: 0;

      + .MuiButtonBase-root {
        margin-left: 24px;
      }
    }

    .MuiButtonBase-root.Mui-selected {
      font-weight: 600;
      color: $brand-link;
    }

    .MuiTabs-indicator {
      background-color: $brand-link;
    }

    .MuiTouchRipple-root {
      display: none;
    }

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
  }

  .app-c-tab__content {
    height: 100%;
    min-height: 0;

    div[role="tabpanel"] {
      height: 100%;
      min-height: 0;
    }

    .MuiBox-root {
      padding-top: 18px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      height: 100%;
      min-height: 0;
    }

    .MuiTypography-root {
      height: 100%;
      min-height: 0;
    }
  }
}
