@use "../../../assets/scss/abstracts/" as *;

.app-c-accordion {
  width: 100%;

  .MuiAccordion-root {
    background: $accordion-bg;
    margin-bottom: 24px !important;
    border-radius: $border-radius4;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border: 1px solid $header-border-color;
    box-shadow: none;

    @media (max-width: $screen-xxl) {
      margin-bottom: 12px !important;
    }

    &::before {
      display: none;
    }

    .MuiAccordionSummary-root {
      padding: 0px 24px;
      border-bottom: 1px solid transparent;

      .MuiAccordionSummary-content {
        // padding: 8px 0 6px 0px;
        margin: 0;
        min-height: 46px;
        align-items: center;
      }

      .MuiAccordionSummary-expandIconWrapper {
        transform: rotate(0deg);

        .MuiButtonBase-root {
          padding: 7px 6px;
          background: #f1e8de;
        }

        .icon-chevron-down {
          width: 12px;
          height: 10px;
        }

        &.Mui-expanded {
          transform: rotate(-180deg);
        }
      }

      &.Mui-expanded {
        min-height: 51px;
        border-color: $header-border-color;
      }
      form {
        position: relative;
        min-width: 345px;
        margin: 0px 32px 0px 0px;

        .form-control {
          background: #ffffff;
          border: 1px solid #e8daca;
          border-radius: 8px;
          padding: 12px 100px 6px 48px;
          width: 100%;
          outline: none;
          font-size: 14px;
          line-height: normal;
        }

        .search-icon {
          position: absolute;
          left: 12px;
          top: 12px;
          color: #6b6a66;
        }

        .search-text {
          font-weight: 500;
          font-size: 14px;
          line-height: normal;
          color: $grey5;
          position: absolute;
          left: 41px;
          top: 11px;
        }
      }
    }

    .MuiCollapse-vertical {
      .MuiCollapse-wrapperInner {
        .MuiAccordionDetails-root {
          padding: 24px;
        }
      }
    }
  }

  .app-l-accordion-title {
    color: $black1;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: normal !important;
    font-family: $body-font !important;
    @extend %inline-flex-all-center;
    height: auto !important;
    margin-right: auto;

    @media (max-width: $screen-xxl) {
      font-size: 16px !important;
    }

    .step-no {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: $menu-text;
      margin-right: 14px;
      padding-top: 2px;
    }

    .import-id {
      @extend %inline-flex-all-center;
      padding: 4px 10px;
      background: #373e50;
      border-radius: 4px;
      margin-left: 14px;
      color: $white;

      span {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
      }

      svg {
        margin-left: 6px;
      }
    }
  }

  .app-c-btn__accordion-action {
    margin-left: auto;
    margin-right: 24px;
    height: 36px;
  }
}

.app-l-accordion__no-data-blk {
  @extend %flex-vert-col;
  margin: 32px 0px;

  .app-l-accordion__no-data-icon {
    .svg-icon {
      font-size: 22px;
      color: $grey5;
      width: 22px;
      height: 22px;
    }
  }

  .app-l-accordion__no-data-text {
    margin-top: 15px;

    span {
      display: block;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $menu-text;
      text-align: center;
    }
  }
}
