@use "./palette" as *;
@use "./variables" as *;
@use "./breakpoints" as *;

%flex {
  display: flex;
}

%flex-col {
  display: flex;
  flex-direction: column;
}
%flex-row {
  display: flex;
  flex-direction: row;
}

%inline-flex-all-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
%inline-flex {
  display: inline-flex; 
}

%inline-flex-vert-center {
  display: inline-flex;
  align-items: center;
}

%flex-vert-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}
%inline-flex-horiz-away-vert-center {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

%flex-all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
%flex-all-center-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

%flex-horiz-center {
  display: flex;
  justify-content: center;
}


%flex-horiz-center-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

%flex-vert-center {
  display: flex;
  align-items: center;
}

%flex-vert-start {
  display: flex;
  align-items: flex-start;
}

%flex-vert-end {
  display: flex;
  align-items: flex-end;
}

%flex-vert-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

%flex-horiz-away-vert-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
%flex-horiz-away-vert-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
%flex-horiz-away {
  display: flex;
  justify-content: space-between;
}

%flex-horiz-end-vert-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
%flex-horiz-end-vert-center {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

%flex-wrap {
  flex-wrap: wrap;
}
%grid{
  display:grid;
  display: -ms-grid;
}
// Flex direction (Firefox needs vendor prefix)
@mixin flex-direction($value: row) {
  -moz-flex-direction: $value;
  flex-direction: $value;
}

// IE11 fix for min height parent with flex align center
%ie11-min-h-flex-center-fix {
  content: "";
  display: block;
  min-height: inherit;
  font-size: 0;
}

%user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

%touch-tap-none {
  -webkit-tap-highlight-color: transparent;
}

%absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

%truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
%no-events-hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

%all-events-shown {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
%visually-hidden{
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

@mixin component-transition($properties){
  transition-property: #{$properties};
  transition-duration:$animation-duration-3;
}