//theme for project

$white: #fff;
$black: #000;
//other shades of palette (supportive colors)

$login-bg: #faf7f2;
$body-bg: #faf7f2;
$brand-black: #111726;
$black1: #333333;

$brand-primary: #e3d1bd;
$brand-link: #968a7e;
$grey1: #111726;
$grey2: #111726;
$grey3: #272c37;
$grey4: #61636f;
$grey5: #8d9091;
$grey6: #bfc3ce;
$grey7: #e5e5e5;
$grey8: #efefef;
$grey9: #fbfbfb;

$grey10: #606060;
$grey11: #383838;
$grey12: #2d2f37;

$grid-header-bg: #f4ede5;
$grid-content: #272c37;
$grid-border: #e8daca;
$menu-text: #6b6a66;
$menu-text-active: #42371f;
$profile-menu-bg: #f4ede5;
$search-bar-border: #ededed;
$header-border-color: #e8daca;
$sidebar-toggle-active: #e2ded3;
$unit-details-bg: #fae8c2;
$input-focus: #f5f5f5;

$status-active: #79b777;
$status-inactive: #9c9c9c;
$status-complete: #70b3ab;
$status-pending: #d6af24;
$status-verified-success: #90cd61;
$status-verified-success1: #91c569;
$status-verified-failed: #e78b69;
$status-qa-success: #39c89d;
$status-qa-failed: #e26d42;
$status-issued: #7ad3a3;
$status-lost: #e07979;
$status-stored: #eca25e;
$status-destroyed: #9c9c9c;
$status-decesed: #636161;
$status-defected: #e26d42;

$danger-red: #be4848;
$accordion-bg: #fcfbf8;
$no-data-text: #7d8b66;
$pale-green: #f5f9ee;
$pale-green-shaded: #dde9c7;
$upload-image-bg: #fbf8f1;
$upload-image-border: #d1c094;

$access-green: #38bf89;
