@use '../abstracts' as *;
@use '../framework/popup' as *;

.app-c-modal {
    @include popup;
    @extend %flex-all-center;

    .MuiBox-root {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        outline: none;
    }

    .app-c-modal__container {
        background-color: rgb(255, 255, 255);
        border-radius: 12px;
        margin: 0px 16px;
    }

    .app-c-modal__header {
        padding: 24px 24px 0px;
        @extend %flex-vert-center;

        h2 {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: $black1;
        }

        .app-c-modal__close {
            margin-left: auto;
            padding: 0px;

            .icon-close {
                width: 12px;
                height: 12px;
                color: $menu-text;
            }
        }
    }

    .app-c-modal__body {
        padding: 20px 24px 24px;
    }

    .app-c-modal__footer {
        padding: 12px 24px;
        text-align: right;
        border-top: 1px solid $header-border-color;
    }

    &--map {
        .MuiBox-root {
            width: 100%;
            max-width: 1193px;
        }

        .map-image {
            width: 100%;
        }

        .app-c-modal--map__container {
            height: 100%;
            max-height: 593px;
            position: relative;
        }
    }

    &--success {
        .MuiBox-root {
            width: 100%;
            max-width: 560px
        }

        .app-c-modal--success__heading {
            margin-top: 24px;
            font-weight: 500;
            font-size: 22px;
            line-height: 32px;
            color: $black;
        }

        .app-c-modal--success__sub-heading {
            margin-top: 6px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $grey3;
        }

        .app-c-modal__body {
            padding: 40px 40px 35px;
            text-align: center;
        }

        .app-c-modal__footer {
            padding: 16px;
            text-align: center;
            border-top: 1px solid $grey8;
        }
    }

    &--image-upload {
        .MuiBox-root {
            width: 100%;
            max-width: 548px
        }

        .app-c-modal__body {
            padding-top: 32px;
            padding-bottom: 0px;
        }


        .app-c-uploaded-images-list {
            @extend %flex-col;
            max-height: 430px;
            overflow: auto;
        }

        .app-c-uploaded-images-list-item {
            @extend %flex-row;

            +.app-c-uploaded-images-list-item {
                margin-top: 24px;
            }
        }

        .app-c-uploaded-images-list-item__left {
            width: 120px;
            height: 120px;
            background: $grey8;
            border: 1px solid $grey6;
            border-radius: 5px;
            flex-shrink: 0;
            position: relative;

            .delete-btn {
                width: 20px !important;
                height: 20px !important;
                background: $white !important;
                border-radius: 50% !important;
                position: absolute;
                right: 5px;
                top: 5px;
                color: $menu-text !important;
                padding: 0px !important;

                .icon-close {
                    width: 8px;
                    height: 8px;
                }
            }

            img {
                width: 100%;
            }
        }

        .app-c-uploaded-images-list-item__right {
            margin-left: 16px;
        }

        .app-c-grant-access__capsule {
            margin: 0px 4px 8px;
            padding: 6px 12px;
        }
    }
    &--carousel {
        .MuiBox-root {
            width: 100%;
            max-width: 1113px
        }

        .app-c-modal__body {
            padding-top: 44px;
            padding-bottom: 0px;
            position: relative;
        }
        .app-c-modal__close {
            position: absolute;
            right: 32px;
            top: 27px;        
            padding: 0px;

            .icon-close {
                width: 12px;
                height: 12px;
                color: $menu-text;
            }
        }
    }
}