  @font-face {
     font-family: 'Inter';
     src: url('../fonts/inter/Inter-Black.eot');
     src: url('../fonts/inter/Inter-Black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/inter/Inter-Black.woff2') format('woff2'),
         url('../fonts/inter/Inter-Black.woff') format('woff'),
         url('../fonts/inter/Inter-Black.ttf') format('truetype'),
         url('../fonts/inter/Inter-Black.svg#Inter-Black') format('svg');
     font-weight: 900;
     font-style: normal;
 }
 
 @font-face {
     font-family: 'Inter';
     src: url('../fonts/inter/Inter-ExtraBold.eot');
     src: url('../fonts/inter/Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/inter/Inter-ExtraBold.woff2') format('woff2'),
         url('../fonts/inter/Inter-ExtraBold.woff') format('woff'),
         url('../fonts/inter/Inter-ExtraBold.ttf') format('truetype'),
         url('../fonts/inter/Inter-ExtraBold.svg#Inter-ExtraBold') format('svg');
     font-weight: bold;
     font-style: normal;
 }
 
 @font-face {
     font-family: 'Inter';
     src: url('../fonts/inter/Inter-Bold.eot');
     src: url('../fonts/inter/Inter-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/inter/Inter-Bold.woff2') format('woff2'),
         url('../fonts/inter/Inter-Bold.woff') format('woff'),
         url('../fonts/inter/Inter-Bold.ttf') format('truetype'),
         url('../fonts/inter/Inter-Bold.svg#Inter-Bold') format('svg');
     font-weight: bold;
     font-style: normal;
 }
 
 @font-face {
     font-family: 'Inter';
     src: url('../fonts/inter/Inter-ExtraLight.eot');
     src: url('../fonts/inter/Inter-ExtraLight.eot?#iefix') format('embedded-opentype'),
         url('../fonts/inter/Inter-ExtraLight.woff2') format('woff2'),
         url('../fonts/inter/Inter-ExtraLight.woff') format('woff'),
         url('../fonts/inter/Inter-ExtraLight.ttf') format('truetype'),
         url('../fonts/inter/Inter-ExtraLight.svg#Inter-ExtraLight') format('svg');
     font-weight: 200;
     font-style: normal;
 }
 
 @font-face {
     font-family: 'Inter';
     src: url('../fonts/inter/Inter-Light.eot');
     src: url('../fonts/inter/Inter-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/inter/Inter-Light.woff2') format('woff2'),
         url('../fonts/inter/Inter-Light.woff') format('woff'),
         url('../fonts/inter/Inter-Light.ttf') format('truetype'),
         url('../fonts/inter/Inter-Light.svg#Inter-Light') format('svg');
     font-weight: 300;
     font-style: normal;
 }
 
 @font-face {
     font-family: 'Inter';
     src: url('../fonts/inter/Inter-Medium.eot');
     src: url('../fonts/inter/Inter-Medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/inter/Inter-Medium.woff2') format('woff2'),
         url('../fonts/inter/Inter-Medium.woff') format('woff'),
         url('../fonts/inter/Inter-Medium.ttf') format('truetype'),
         url('../fonts/inter/Inter-Medium.svg#Inter-Medium') format('svg');
     font-weight: 500;
     font-style: normal;
 }
 
 @font-face {
     font-family: 'Inter';
     src: url('../fonts/inter/Inter-Regular.eot');
     src: url('../fonts/inter/Inter-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/inter/Inter-Regular.woff2') format('woff2'),
         url('../fonts/inter/Inter-Regular.woff') format('woff'),
         url('../fonts/inter/Inter-Regular.ttf') format('truetype'),
         url('../fonts/inter/Inter-Regular.svg#Inter-Regular') format('svg');
     font-weight: normal;
     font-style: normal;
 }
 
 @font-face {
     font-family: 'Inter';
     src: url('../fonts/inter/Inter-SemiBold.eot');
     src: url('../fonts/inter/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/inter/Inter-SemiBold.woff2') format('woff2'),
         url('../fonts/inter/Inter-SemiBold.woff') format('woff'),
         url('../fonts/inter/Inter-SemiBold.ttf') format('truetype'),
         url('../fonts/inter/Inter-SemiBold.svg#Inter-SemiBold') format('svg');
     font-weight: 600;
     font-style: normal;
 }
 
 @font-face {
     font-family: 'Inter';
     src: url('../fonts/inter/Inter-Thin.eot');
     src: url('../fonts/inter/Inter-Thin.eot?#iefix') format('embedded-opentype'),
         url('../fonts/inter/Inter-Thin.woff2') format('woff2'),
         url('../fonts/inter/Inter-Thin.woff') format('woff'),
         url('../fonts/inter/Inter-Thin.ttf') format('truetype'),
         url('../fonts/inter/Inter-Thin.svg#Inter-Thin') format('svg');
     font-weight: 100;
     font-style: normal;
 }
 
 