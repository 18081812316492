@use "../abstracts" as *;

.Toastify__toast-container--top-right {
  top: 12px !important;
  right: 0px !important;
  padding: 0px;
  width: max-content !important;
}

.Toastify__toast {
  border-radius: 10px 0px 0px 10px;
  min-height: 60px;
  color: $black !important;
  font-weight: 500;
  font-size: 14px;
  padding: 11px 18px 8px 8px;

  &::before {
    content: "";
    position: absolute;
    width: 7px;
    left: 0px;
    right: auto;
    top: 0px;
    bottom: 0px;
  }

  &--success {
    background: #ecf5ee !important;

    &::before {
      background: #3ac279 !important;
    }

    .Toastify__toast-icon {
      svg {
        color: #3ac279 !important;
      }
    }
  }

  &--error {
    background: #fff2f2 !important;

    &::before {
      background: #e4584f !important;
    }

    .Toastify__toast-icon {
      svg {
        color: #e4584f !important;
      }
    }
  }
}

.Toastify__toast-body {
  padding: 6px 15px 6px 12px;
}

.Toastify__toast-icon {
  margin-right: 15px;
}

.Toastify__close-button {
  align-self: center;
  color: $menu-text;
  opacity: 1;

  svg {
    width: 12px;
    height: 12px;
  }
}
